<template>
  <div class="myMessageList">
    <!-- 顶部导航栏 -->
    <van-nav-bar
      :title="title"
      left-text="返回"
      right-text="全部已读"
      left-arrow
      fixed
      placeholder
      safe-area-inset-top
      @click-left="$router.back()"
    >
      <template #right>
        <van-popover
          v-model="showPopover"
          trigger="click"
          :actions="actions"
          placement="bottom-end"
          :offset="[15, 2]"
          @select="selectOption"
        >
          <template #reference>
            <van-icon
              name="ellipsis"
              size="18"
            />
          </template>
        </van-popover>
      </template>
    </van-nav-bar>
    <!-- 搜索 -->
    <form action="/">
      <van-search
        v-model="value"
        :placeholder="placeholderObj[$route.params.type]"
        @search="onSearch"
        @input="onInput"
      />
    </form>
    <!-- 消息列表 -->
    <div
      class="messageList"
      v-watermark
      @scroll="onScroll"
      ref="messageList"
    >
      <!-- 系统消息列表 -->
      <div
        class="list"
        v-if="(messageList.length || refreshing) && $route.params.type === '3'"
      >
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :error.sync="error"
            error-text="请求失败,点击重新加载!"
          >
            <!-- 卡片 -->
            <van-cell
              v-for="(item, index) in messageList"
              :key="index"
              class="card"
              :name="index"
              @click="messageClick(item.read, item.id, item.title, JSON.parse(item.content), index)"
            >
              <div class="detail">
                <p class="title">
                  {{ item.titleName }}
                  <van-badge
                    dot
                    v-if="item.read === 0"
                  ></van-badge>
                  <span class="date">
                    {{ item.time }}
                  </span>
                </p>
                <p
                  class="message"
                  v-if="['103'].includes(item.title)"
                >
                  版本号: {{ JSON.parse(item.content).code || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['103'].includes(item.title)"
                >
                  升级内容: {{ JSON.parse(item.content).context || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['104'].includes(item.title)"
                  v-html="JSON.parse(item.content).context"
                >
                </p>
              </div>
            </van-cell>
          </van-list>
        </van-pull-refresh>
      </div>
      <!-- 单位消息列表 -->
      <div
        class="list"
        v-else-if="(messageList.length || refreshing) && $route.params.type === '0'"
      >
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :error.sync="error"
            error-text="请求失败,点击重新加载!"
          >
            <!-- 卡片 -->
            <van-cell
              v-for="(item, index) in messageList"
              :key="index"
              class="card"
              :name="index"
              @click="messageClick(item.read, item.id, item.title, JSON.parse(item.content), index)"
            >
              <div class="detail">
                <p class="title">
                  {{ item.titleName }}
                  <van-badge
                    dot
                    v-if="item.read === 0"
                  ></van-badge>
                  <span class="date">
                    {{ item.time }}
                  </span>
                </p>
                <p class="message">
                  单位编号: {{ JSON.parse(item.content).code || "--" }}
                </p>
                <p class="message">
                  单位名称: {{ JSON.parse(item.content).name || "--" }}
                </p>
              </div>
            </van-cell>
          </van-list>
        </van-pull-refresh>
      </div>
      <!-- 设备消息列表 -->
      <div
        class="list"
        v-else-if="(messageList.length || refreshing) && $route.params.type === '1'"
      >
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :error.sync="error"
            error-text="请求失败,点击重新加载!"
          >
            <!-- 卡片 -->
            <van-cell
              v-for="(item, index) in messageList"
              :key="index"
              class="card"
              :name="index"
              @click="messageClick(item.read, item.id, item.title, JSON.parse(item.content), index)"
            >
              <div class="detail">
                <p class="title">
                  {{ item.titleName }}
                  <van-badge
                    dot
                    v-if="item.read === 0"
                  ></van-badge>
                  <span class="date">
                    {{ item.time }}
                  </span>
                </p>
                <p
                  class="message"
                  v-if="['11', '12', '13', '14', '15', '16', '24'].includes(item.title)"
                >
                  单位编号: {{ JSON.parse(item.content).code || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['11', '12', '13', '14', '15', '16', '24'].includes(item.title)"
                >
                  单位名称: {{ JSON.parse(item.content).name || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['11', '12', '13'].includes(item.title)"
                >
                  终端序号: {{ JSON.parse(item.content).equipmentId || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['14', '15', '16'].includes(item.title)"
                >
                  物联ID: {{ JSON.parse(item.content).equipmentId || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['24'].includes(item.title)"
                >
                  主机序号: {{ JSON.parse(item.content).equipmentId || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['17'].includes(item.title)"
                >
                  主机品牌: {{ JSON.parse(item.content).brandName || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['17'].includes(item.title)"
                >
                  主机型号: {{ JSON.parse(item.content).code || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['18'].includes(item.title)"
                >
                  设备品牌: {{ JSON.parse(item.content).brandName || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['18'].includes(item.title)"
                >
                  设备型号: {{ JSON.parse(item.content).code || "--" }}
                </p>
              </div>
            </van-cell>
          </van-list>
        </van-pull-refresh>
      </div>
      <!-- 合同消息列表 -->
      <div
        class="list"
        v-else-if="(messageList.length || refreshing) && $route.params.type === '5'"
      >
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :error.sync="error"
            error-text="请求失败,点击重新加载!"
          >
            <!-- 卡片 -->
            <van-cell
              v-for="(item, index) in messageList"
              :key="index"
              class="card"
              :name="index"
              @click="messageClick(item.read, item.id, item.title, JSON.parse(item.content), index)"
            >
              <div class="detail">
                <p class="title">
                  {{ item.titleName }}
                  <van-badge
                    dot
                    v-if="item.read === 0"
                  ></van-badge>
                  <span class="date">
                    {{ item.time }}
                  </span>
                </p>
                <p
                  class="message"
                  v-if="['4', '5', '8', '102', '22', '23'].includes(item.title)"
                >
                  合同号: {{ JSON.parse(item.content).code || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['4', '5', '8', '102', '22', '23'].includes(item.title)"
                >
                  单位名称: {{ JSON.parse(item.content).name || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['8'].includes(item.title)"
                >
                  状态: {{ JSON.parse(item.content).status || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['3'].includes(item.title)"
                >
                  纳税人识别号: {{ JSON.parse(item.content).taxIdNumber || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['3'].includes(item.title)"
                >
                  开票抬头: {{ JSON.parse(item.content).title || "--" }}
                </p>
                <p
                  class="message"
                  v-if="['102'].includes(item.title)"
                >
                  距离到期: {{ `还有${JSON.parse(item.content).days}天` }}
                </p>
              </div>
            </van-cell>
          </van-list>
        </van-pull-refresh>
      </div>
      <!-- 个人消息列表 -->
      <div
        class="list"
        v-else-if="(messageList.length || refreshing) && $route.params.type === '7'"
      >
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :error.sync="error"
            error-text="请求失败,点击重新加载!"
          >
            <!-- 卡片 -->
            <van-cell
              v-for="(item, index) in messageList"
              :key="index"
              class="card"
              :name="index"
              @click="messageClick(item.read, item.id, item.title, JSON.parse(item.content), index)"
            >
              <div class="detail">
                <p class="title">
                  {{ item.titleName }}
                  <van-badge
                    dot
                    v-if="item.read === 0"
                  ></van-badge>
                  <span class="date">
                    {{ item.time }}
                  </span>
                </p>
                <p class="message">
                  用户名: {{ JSON.parse(item.content).name || "--" }}
                </p>
                <p class="message">
                  距离到期: {{ `还有${JSON.parse(item.content).days}天` }}
                </p>
              </div>
            </van-cell>
          </van-list>
        </van-pull-refresh>
      </div>
      <!-- 供应商消息列表 -->
      <div
        class="list"
        v-else-if="(messageList.length || refreshing) && $route.params.type === '6'"
      >
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :error.sync="error"
            error-text="请求失败,点击重新加载!"
          >
            <!-- 卡片 -->
            <van-cell
              v-for="(item, index) in messageList"
              :key="index"
              class="card"
              :name="index"
              @click="messageClick(item.read, item.id, item.title, JSON.parse(item.content), index)"
            >
              <div class="detail">
                <p class="title">
                  {{ item.titleName }}
                  <van-badge
                    dot
                    v-if="item.read === 0"
                  ></van-badge>
                  <span class="date">
                    {{ item.time }}
                  </span>
                </p>
                <p class="message">
                  供应商编号: {{ JSON.parse(item.content).code || "--" }}
                </p>
                <p class="message">
                  单位名称: {{ JSON.parse(item.content).name || "--" }}
                </p>
              </div>
            </van-cell>
          </van-list>
        </van-pull-refresh>
      </div>
      <!-- 空 -->
      <van-empty
        description="暂无相关消息"
        v-else
      />
    </div>
  </div>
</template>

<script>
import { getMessageList, readMessage, removeMessage } from '@/api/message'
import { mapActions, mapState } from 'vuex'
import { Dialog } from 'vant'
let timeId = null
export default {
  name: 'messageList',
  data() {
    return {
      placeholderObj: {
        0: '请输入单位编号或名称',
        1: '请输入单位编号或名称',
        3: '请输入搜索关键词',
        4: '请输入任务单号、单位编号或名称',
        5: '请输入合同号或单位名称',
        6: '请输入供应商编号或名称',
        7: '请输入搜索关键词'
      },
      value: '',
      messageList: [],
      top: 0,
      finished: false,
      loading: false,
      refreshing: false,
      pageSize: 10,
      id: '',
      error: false,
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [{ text: '全部已读' }, { text: '全部清除' }],
      type: this.$route.params.type
    }
  },
  computed: {
    ...mapState('count', ['messageTypeList']),
    title() {
      switch (this.$route.params.type) {
        case '0':
          return '单位消息'
        case '1':
          return '设备消息'
        case '2':
          return '物料消息'
        case '3':
          return '系统消息'
        case '4':
          return '任务消息'
        case '5':
          return '合同消息'
        case '6':
          return '供应商消息'
        case '7':
          return '个人消息'
        default:
          return '消息'
      }
    }
  },
  activated() {
    if (this.$refs.messageList) {
      this.$refs.messageList.scrollTop = this.top
    }
  },
  // 路由进入页面
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('Info') && !to.params.Refresh) {
      next(async vm => {
        if (!vm.messageList.length || vm.type !== vm.$route.params.type) {
          Object.assign(vm.$data, vm.$options.data.call(vm))
          vm.refreshing = true
          vm.onRefresh()
        }
      })
    } else {
      next(async vm => {
        Object.assign(vm.$data, vm.$options.data.call(vm))
        vm.refreshing = true
        vm.onRefresh()
      })
    }
  },
  // 路由离开页面
  beforeRouteLeave(to, from, next) {
    clearTimeout(timeId)
    next()
  },
  methods: {
    ...mapActions('count', ['getMessageCenList']),
    onSearch() {
      if (!this.context) {
        return
      }
      this.id = ''
      this.error = false
      this.top = 0
      if (this.$refs.messageList) {
        this.$refs.messageList.scrollTop = 0
      }
      this.refreshing = true
      this.onRefresh()
    },
    onInput(val) {
      clearTimeout(timeId)
      timeId = setTimeout(() => {
        this.id = ''
        this.error = false
        this.top = 0
        if (this.$refs.messageList) {
          this.$refs.messageList.scrollTop = 0
        }
        this.refreshing = true
        this.onRefresh()
      }, 300)
    },
    // 滚动高度
    onScroll(e) {
      this.top = e.target.scrollTop
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    // 加载列表
    async onLoad() {
      if (this.refreshing) {
        this.id = ''
      }
      try {
        const { data } = await getMessageList({
          id: this.id,
          size: this.pageSize,
          type: this.$route.params.type,
          systemCode: '0',
          context: this.value ? this.value : null
        })
        // 追加数据
        const results = data.data.records
        if (this.refreshing) {
          this.refreshing = false
          this.messageList = []
        }
        this.messageList.push(...results)
        this.loading = false
        if (results.length) {
          this.id =
            results[results.length - 1].id
        }
        if (!results.length) {
          this.finished = true
        }
      } catch (error) {
        if (error.message === '取消请求') {
          this.error = false
          return
        }
        // 加载状态结束
        this.loading = false
        this.error = true
        if (this.refreshing) {
          this.refreshing = false
        }
        if (
          error?.response?.status === 401
        ) {
          return
        }
        if (error.message && error.message.includes('timeout')) {
          this.$toast.fail({
            message: '网络不好，请稍后再试!',
            duration: 500
          })
          return
        }
        this.$toast.fail({
          message: '消息列表获取失败,请稍后再试!',
          duration: 500
        })
      }
    },
    // 点击气泡选项
    selectOption(action, index) {
      switch (index) {
        case 0:
          this.allRead()
          break
        case 1:
          this.allClear()
          break
      }
    },
    // 消息已读
    async readMessage(messageId, index) {
      await readMessage({ id: messageId, systemCode: '0' })
      this.getMessageCenList()
      this.messageList[index].read = 1
    },
    // 全部已读
    async allRead() {
      if (this.refreshing) {
        return
      }
      if (!this.messageList.length || !this.messageTypeList.filter((item) => item.type === this.$route.params.type)[0].count) {
        return
      }
      await readMessage({ type: this.$route.params.type, systemCode: '0' })
      this.messageList.forEach(item => {
        item.read = 1
      })
      this.getMessageCenList()
    },
    // 全部清空
    async allClear() {
      if (this.refreshing) {
        return
      }
      if (!this.messageList.length) {
        return
      }
      await removeMessage({ type: this.$route.params.type, systemCode: '0' })
      this.messageList = []
      this.refreshing = true
      this.onRefresh()
      this.getMessageCenList()
    },
    // 点击消息
    messageClick(read, messageId, messageCode, info, index) {
      if (this.refreshing) {
        return
      }
      switch (messageCode) {
        case '0':
          // 跳转联网单位
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push(`/workTable/companyAddressInfo/${info.id}`)
          break
        case '1':
          // 跳转单位联系人
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push(`/workTable/contactAddressInfo/${info.id}`)
          break
        case '2':
          // 跳转联网单位开通记录
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push({
            path: `/workTable/companyAddressInfo/${info.id}`,
            query: {
              openId: info.openId,
              type: messageCode
            }
          })
          break
        case '3':
          // 跳转开票信息
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push(`/workTable/invoiceInfoAddressInfo/${info.id}`)
          break
        case '4':
          // 跳转商务合同
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push({
            name: 'agreementAddressInfo',
            params: {
              Refresh: true,
              id: info.id
            }
          })
          break
        case '5':
          // 跳转施工协议
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push({
            name: 'constructionAddressInfo',
            params: {
              Refresh: true,
              id: info.id
            }
          })
          break
        case '8':
          // 跳转施工协议
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push({
            name: 'constructionAddressInfo',
            params: {
              Refresh: true,
              id: info.id
            }
          })
          break
        case '9':
          // 跳转供应商
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push(`/workTable/supplierAddressInfo/${info.id}`)
          break
        case '11':
          // 跳转单位终端
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push(`/workTable/companyTerminalAddressBookInfo/${info.id}`)
          break
        case '12':
          // 跳转单位终端开通记录
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push({
            path: `/workTable/companyTerminalAddressBookInfo/${info.id}`,
            query: {
              openId: info.openId,
              type: messageCode
            }
          })
          break
        case '13':
          // 跳转单位终端换卡记录
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push({
            path: `/workTable/companyTerminalAddressBookInfo/${info.id}`,
            query: {
              changeCardId: info.changeCardId,
              type: messageCode
            }
          })
          break
        case '14':
          // 跳转单位物联
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push(`/workTable/companyInternetAddressBookInfo/${info.id}`)
          break
        case '15':
          // 跳转单位物联开通记录
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push({
            path: `/workTable/companyInternetAddressBookInfo/${info.id}`,
            query: {
              openId: info.openId,
              type: messageCode
            }
          })
          break
        case '16':
          // 跳转单位物联开通记录
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push({
            path: `/workTable/companyInternetAddressBookInfo/${info.id}`,
            query: {
              changeCardId: info.changeCardId,
              type: messageCode
            }
          })
          break
        case '17':
          // 跳转消防主机型号
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push(`/workTable/hostModelAddressBookInfo/${info.id}`)
          break
        case '18':
          // 跳转设备型号
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push(`/workTable/internetModelAddressInfo/${info.id}`)
          break
        case '24':
          // 跳转单位终端消防主机
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push({
            path: `/workTable/companyTerminalAddressBookInfo/${info.id}`,
            query: {
              equipmentTerminalHostId: info.equipmentTerminalHostId,
              type: messageCode
            }
          })
          break
        case '22':
          // 跳转商务合同计费
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push({
            name: 'historyChargingInfo',
            params: {
              Refresh: true,
              id: info.id
            },
            query: {
              year: info.time
            }
          })
          break
        case '23':
          // 跳转施工协议计费
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push({
            name: 'historyconstructionChargingInfo',
            params: {
              Refresh: true,
              id: info.id
            },
            query: {
              year: info.time
            }
          })
          break
        case '102':
          // 跳转商务合同
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push({
            name: 'agreementAddressInfo',
            params: {
              Refresh: true,
              id: info.id
            }
          })
          break
        case '103':
          // 跳转系统更新详情
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push(`/update/updateInfo/${info.id}`)
          break
        case '104':
          // 系统发公告展示
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          Dialog.alert({
            title: info.name,
            message: '<strong style="color:black">公告内容:</strong>' + info.context,
            confirmButtonColor: '#1989FA',
            messageAlign: 'left',
            className: 'noticeDialog'
          }).then(() => {
            // on close
          })
          break
        case '101':
          // 跳转个人信息详情
          if (read === 0) {
            this.readMessage(messageId, index)
          }
          this.$router.push('/userInfo')
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.myMessageList {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #eeeeee;

  ::v-deep {
    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .messageList {
      height: calc(100% - 54px - 46px);
      overflow: auto;

      .list {
        padding-top: 10px;

        .van-cell {
          padding: 0;
          line-height: normal;
        }

        .card {
          margin-bottom: 10px;
          background-color: white;
          position: static;

          .van-swipe-cell__right {
            display: flex;
            align-items: center;
            background-color: red;

            .delet {
              font-size: 14px;
              text-align: center;
              padding: 0 6px;
              color: white;
            }
          }

          .detail {
            padding: 12px;

            .message {
              font-size: 12px;
              color: #969799;
              word-break: break-all;
              padding-bottom: 5px;
            }

            .title {
              color: black;
              font-size: 14px;
              position: relative;
              margin-bottom: 7px;
              font-weight: 700;

              .van-badge--dot {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin-left: 6px;
              }

              .date {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                line-height: 1;
                right: 5px;
                font-weight: 400;
                color: #969799;
              }
            }
          }

        }
      }

      .van-empty {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .van-empty__description {
          padding: 0;
        }
      }
    }
  }
}
</style>
